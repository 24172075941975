import React, { Fragment } from "react";
import { graphql } from "gatsby";

import Seo from "../../components/seo";

import Projects from "../../containers/projects";

const ExhibitionsPage = ({ data }) => {
  const { allFile } = data;
  const projects = allFile.edges;

  return (
    <Fragment>
      <Seo title="Projects" />
      <Projects projects={projects} />
    </Fragment>
  );
};

export const projectQuery = graphql`
  query ProjectsQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "projects" } }
      sort: {
        fields: childMarkdownRemark___frontmatter___startDate
        order: DESC
      }
    ) {
      edges {
        node {
          id
          sourceInstanceName
          childMarkdownRemark {
            frontmatter {
              images {
                image
              }
              description
              endDate
              institution
              location
              position
              startDate
              hasEndDate
              endDate
              title
              path
              pressLinks {
                pressLinkTitle
                pressLinkURL
              }
            }
          }
        }
      }
    }
  }
`;

export default ExhibitionsPage;
