import React from "react";
import styled from "styled-components";
import { format } from "date-fns";

// import Img from "react-image";
import Image from "../components/image";
import { Link } from "gatsby";

const Projects = styled.ul``;
const Project = styled.li`
  margin-bottom: 4rem;
`;
Project.Title = styled(Link)`
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1px;
  text-decoration: none;
`;

Project.Information = styled.p``;
Project.Date = styled.p`
  margin-bottom: 1rem;
  opacity: 0.7;
`;
Project.Description = styled.p`
  position: relative;
  margin-bottom: 1rem;
  max-width: 600px;
`;

Project.Thumbnails = styled(Link)`
  min-height: 80px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

Project.Thumbnail = styled(Image)`
  flex: 0 0 auto;
  margin-right: 1rem;
  display: inline-block;
  height: 80px;
  max-height: 80px;
  border-radius: 5px;
  :first-of-type {
    margin-left: 0;
  }
`;

const ProjectsContainer = ({ projects }) => {
  return (
    <Projects>
      <Project>
            <Project.Title
              to={`https://bladestudy.net`}
            >
              Blade Study
            </Project.Title>
            <Project.Information>
              17 Pike Street, New York, NY 10002
            </Project.Information>
            <Project.Date>
              September 1, 2022
            </Project.Date>
            <Project.Description>Co-Founder and Co-Director with collaborator <a href="https://ianglover.com" target="_blank" rel="noreferrer">Ian Glover</a>.</Project.Description>
          </Project>
      {projects.map((project) => {
        const { node } = project;
        const { childMarkdownRemark } = node;
        const { frontmatter } = childMarkdownRemark;
        const {
          title,
          description,
          institution,
          location,
          images,
          startDate,
          hasEndDate,
          endDate,
        } = frontmatter;
        return (
          <Project key={title}>
            <Project.Title
              to={`/${title.replace(/[^0-9a-zA-Z]/g, "-").toLowerCase()}`}
            >
              {title}
            </Project.Title>
            <Project.Information>
              {institution}
              {location ? `, ${location}` : null}
            </Project.Information>
            <Project.Date>
              {format(new Date(startDate), "MMMM d, yyyy")}{" "}
              {hasEndDate
                ? `- ${format(new Date(endDate), "MMMM d, yyyy")}`
                : null}
            </Project.Date>
            <Project.Description>{description}</Project.Description>
            <Project.Thumbnails
              to={`/${title.replace(/[^0-9a-zA-Z]/g, "-").toLowerCase()}`}
            >
              {images
                ? images.map((image) => {
                    return (
                      <Project.Thumbnail key={image.image} src={image.image} />
                    );
                  })
                : null}
            </Project.Thumbnails>
          </Project>
        );
      })}
    </Projects>
  );
};

export default ProjectsContainer;
